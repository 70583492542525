import type { Prisma } from "@prisma/client";
import { addBusinessDays } from "app/shared/dates";
import { bookingStatuses } from "app/shared/schemas/utils.schema";
import { type ClassValue, clsx } from "clsx";
import type { dataTableSearchParams } from "components/Layout/Admin/Tables/AdminDataTable";
import type { inferParserType } from "nuqs";
import { isRouteErrorResponse, useRouteLoaderData } from "react-router";
import { ProviderDocumentTypes } from "server/api/types/provider-documents.types";
import { twMerge } from "tailwind-merge";
import z from "zod";
/*
 * Filtre les bookings par status
 */
export const getOperationsFilters = (
  operationsFilter: inferParserType<
    typeof dataTableSearchParams
  >["operationsFilter"],
): Prisma.BookingWhereInput => {
  const tomorrow = addBusinessDays({ date: new Date(), daysToAdd: 1 });
  tomorrow.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  switch (operationsFilter) {
    case "all": {
      return {};
    }
    case "all-operations": {
      return {
        OR: [
          // Bookings qui commencent demain (jour ouvré)
          {
            AND: [
              {
                eventStartDate: {
                  equals: tomorrow,
                },
              },
              {
                status: {
                  gcId: {
                    gte: bookingStatuses.CONFIRMED.id,
                    lt: bookingStatuses.REFUSED_BY_PROVIDER.id,
                  },
                },
              },
            ],
          },
          // Bookings qui finissent demain (jour ouvré)
          {
            AND: [
              {
                eventEndDate: {
                  equals: tomorrow,
                },
              },
              {
                status: {
                  gcId: {
                    gte: bookingStatuses.CONFIRMED.id,
                    lt: bookingStatuses.REFUSED_BY_PROVIDER.id,
                  },
                },
              },
            ],
          },
          // Bookings avec date de fin passée sans statut clôturé
          {
            AND: [
              {
                eventEndDate: {
                  lt: today,
                },
              },
              {
                status: {
                  gcId: {
                    gte: bookingStatuses.CONFIRMED.id,
                    lt: bookingStatuses.REFUSED_BY_PROVIDER.id,
                    not: bookingStatuses.CLOSED_FINAL_INVOICE_PAID.id,
                  },
                },
              },
              {
                documents: {
                  none: {
                    documentType: {
                      equals: ProviderDocumentTypes.BOOKING_WEIGHT_TICKET,
                    },
                  },
                },
              },
            ],
          },
          // Bookings avec date de début passée mais sans date de fin
          {
            AND: [
              {
                eventStartDate: {
                  lt: today,
                },
              },
              {
                status: {
                  gcId: {
                    equals: bookingStatuses.IN_PROGRESS.id,
                  },
                },
              },
              {
                eventEndDate: null,
              },
            ],
          },
        ],
      };
    }
    case "put": {
      return {
        AND: [
          {
            eventStartDate: {
              equals: tomorrow,
            },
          },
          {
            status: {
              gcId: {
                gte: bookingStatuses.CONFIRMED.id,
                lt: bookingStatuses.REFUSED_BY_PROVIDER.id,
              },
            },
          },
        ],
      };
    }
    case "withdraw": {
      return {
        AND: [
          {
            eventEndDate: {
              equals: tomorrow,
            },
          },
          {
            status: {
              gcId: {
                gte: bookingStatuses.CONFIRMED.id,
                lt: bookingStatuses.REFUSED_BY_PROVIDER.id,
              },
            },
          },
        ],
      };
    }
    case "without-close": {
      // Bookings avec date de fin passée sans statut clôturé
      return {
        AND: [
          {
            eventEndDate: {
              lt: today,
            },
          },
          {
            status: {
              gcId: {
                gte: bookingStatuses.CONFIRMED.id,
                lt: bookingStatuses.REFUSED_BY_PROVIDER.id,
                not: {
                  in: [
                    bookingStatuses.CLOSED_FINAL_INVOICE_PAID.id,
                    bookingStatuses.CLOSED_PENDING_FINAL_INVOICE_PAYMENT.id,
                    bookingStatuses
                      .FINALIZED_DOWNGRADING_VALIDATED_PENDING_CUSTOMER_CONFIRMATION
                      .id,
                  ],
                },
              },
            },
          },
          {
            documents: {
              none: {
                documentType: {
                  equals: ProviderDocumentTypes.BOOKING_WEIGHT_TICKET,
                },
              },
            },
          },
        ],
      };
    }
    case "without-end-date": {
      return {
        AND: [
          {
            eventStartDate: {
              lt: today,
            },
          },
          {
            status: {
              gcId: {
                equals: bookingStatuses.IN_PROGRESS.id,
              },
            },
          },
          {
            eventEndDate: null,
          },
        ],
      };
    }
    default: {
      return {};
    }
  }
};
/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit["headers"] | null | undefined>
) {
  const combined = new Headers();
  for (const header of headers) {
    if (!header) continue;
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value);
    }
  }
  return combined;
}

export function useIsDevmode() {
  const devData = z
    .object({
      isDev: z.boolean(),
    })
    .safeParse(useRouteLoaderData("root"));

  if (!devData.success) {
    return false;
  }
  return devData.data.isDev;
}

// Transform text into anchor link (like h1, h2 titles in a blog)
export function getAnchor(text: string | any) {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/[ ]/g, "-");
}

export type BookingPriceParams = {
  service: string;
  volume: string;
  address: string;
  placeId: string;
  waste: string | null;
  startDate: string;
  endDate: string;
  userId?: string;
  isRecurring: "1" | "0";
  isProfessional: "1" | "0";
  plans: "1" | "0";
  immediatePickup: "1" | "0";
  currentStep: 1 | 2 | 3 | 4;
};

export const prettifyBookingId = (id: number) => {
  return `GC - ${id.toString().padStart(6, "0")}`;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isNumber = (value: string) => {
  if (!value) return false;
  const numberedValue = Number(value);
  const isNumber = !isNaN(numberedValue);
  return isNumber;
};

export const formatSiret = (siretAsString: string) => {
  if (siretAsString.length < 14)
    return siretAsString.replace(/(\d{3})(?=\d)/g, "$1 ");
  return siretAsString.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, "$1 $2 $3 $4");
};

export const removeStringSpaces = (value: string) => {
  return value.replace(/\s/g, "");
};

export function getErrorMessage(error: unknown) {
  if (typeof error === "string") return error;
  if (error && typeof error === "object" && "message" in error) {
    if (typeof error.message === "string") {
      return error.message;
    }
    if (typeof error.message === "object") {
      const errorArrays = error.message as string[];
      return errorArrays?.join(", ");
    }
    if (isRouteErrorResponse(error)) {
      // @ts-ignore
      return error.data.error.message;
    }
  }
  console.error("Unable to get error message for error", error);
  return "Erreur inconnue";
}

type QueryParams = Record<string, string | number>;

interface RedirectUrlConfig<TUrl extends string> {
  url: TUrl;
  params?: QueryParams;
  redirectUrl: string;
  redirectParams?: QueryParams;
  additionalParams?: QueryParams;
}
export const generateRedirectToUrl = <TUrl extends string>({
  url,
  redirectUrl,
  redirectParams = {},
}: RedirectUrlConfig<TUrl>): `${TUrl}?${string}` => {
  // First create the target redirect URL with its parameters
  const targetUrlParams = new URLSearchParams();

  // Add redirect parameters to the target URL
  for (const [key, value] of Object.entries(redirectParams)) {
    targetUrlParams.set(key, value?.toString());
  }

  // Create the full target URL with its parameters
  const fullTargetUrl = targetUrlParams.toString()
    ? `${redirectUrl}?${targetUrlParams.toString()}`
    : redirectUrl;

  // Create the main URL parameters
  const mainUrlParams = new URLSearchParams();
  mainUrlParams.set("redirectTo", fullTargetUrl);

  // Generate the final URL
  return `${url}?${mainUrlParams.toString()}`;
};

export const getLastParamFromPath = (path: string): string => {
  const parts = path.split("/");
  return parts[parts.length - 1] || path;
};
